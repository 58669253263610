export enum ReportedProblemUrgency {
  low = "low",
  high = "high",
}

export const ReportedProblemUrgencies = [
  {
    type: ReportedProblemUrgency.low,
    label: "Low: I can still grade.",
  },

  {
    type: ReportedProblemUrgency.high,
    label: "High: I cannot grade!",
  },
];
